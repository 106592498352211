import { Box, Button, Typography } from "@mui/material"
import SignzyLogo from "../../assets/images/signzyLogo.png";
import OTPInput from "../custom-inputs/OTPInput"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { loginApi, verifyOTP } from "../../api/apiCalls";
import { useEffect, useState } from "react";
import { setPassWord, setRememberUser, setUser } from "../../redux/slices/userSlice";
import { setLoginFormError, setLoginFormState } from "../../redux/slices/formStateSlice";
import { useDispatch } from "react-redux";
import { notify } from "../../context/notificationService";


const OTPForm = ({ setLoading }: { setLoading: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  const loginForm = useSelector((state: RootState) => state.forms.loginForm);
  const dispatch = useDispatch();

  const [otp, setOTP] = useState('');
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsResendDisabled(false);
      clearInterval(interval!);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer, isResendDisabled]);

  const handleResend = async () => {
    setLoading(true);
    try {
      const data = await loginApi(loginForm.username, loginForm.password);
      const { message, id } = data;
      dispatch(setLoginFormState({ otpId: id }));
      setIsResendDisabled(true);
      setTimer(60);
      notify(message, 'success');
    } catch (error) {
      console.log("resend otp error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOTP = async () => {
    setLoading(true);
    try {
      const data = await verifyOTP(loginForm.otpId, otp);
      dispatch(setUser({ username: data.username, token: data.token, password: '', expiryTime: Date.now() + 86350000 }));
      dispatch(setRememberUser(loginForm.rememberMe));
      if (loginForm.rememberMe) {
        dispatch(setPassWord(loginForm.password));
      }
      dispatch(setLoginFormState({ username: '', password: '', rememberMe: true, otpId: '' }));
      dispatch(setLoginFormError([]));
    } catch (error) {
      console.log("verify otp error:",error);
    }finally{
      setLoading(false);
    }
  }
  return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '100px', gap: '20px' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
      <img src={SignzyLogo} alt="Signzy" style={{ height: "66px" }} />
    </Box>
    <Typography variant="h4" component="h2" sx={{ color: 'secondary.main', fontWeight: 700, textAlign: 'left', fontSize: '28px' }}>Please check your email!</Typography>
    <Typography variant="body1" component="p" sx={{ color: '#637381', fontWeight: 400, textAlign: 'left', fontSize: 16 }}>We've emailed a 6-digit confirmation code to {loginForm.username}, please enter the code in below box to verify your email.</Typography>
    <OTPInput value={otp} onChange={setOTP} />
    <Button variant="contained" sx={{ backgroundColor: 'primary', color: 'text.secondary', height: '48px', fontWeight: 700, textTransform: 'none' }} type="button" onClick={handleSubmitOTP} disabled={otp.length !== 6 ? true : false}>Verify</Button>
    <Box sx={{ display: "flex", alignItems: "center" }}>Don't have a code? <Button sx={{ fontWeight: "700" }} variant="text" onClick={handleResend} disabled={isResendDisabled}>{isResendDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}</Button></Box>
  </Box>
}

export default OTPForm;