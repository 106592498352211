import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginForm } from "../types";

interface formState {
  loginFormErrors: string[];
  loginForm: LoginForm;
}

const initialState: formState = {
  loginFormErrors: [],
  loginForm:{
    username:"",
    password:"",
    rememberMe:true,
    otpId:"",
  }
}
const formStateSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setLoginFormError(state,action:PayloadAction<string[]>){
      state.loginFormErrors = action.payload;
    },
    setLoginFormState(state,action:PayloadAction<{username?:string,password?:string,rememberMe?:boolean,otpId?:string}>){
      const {username,password,rememberMe,otpId}= action.payload;
      if(username !== undefined){
        state.loginForm.username = username;
      }
      if(password !== undefined){
        state.loginForm.password = password;
      }
      if(rememberMe !== undefined){
        state.loginForm.rememberMe = rememberMe;
      }
      if(otpId !== undefined){
        state.loginForm.otpId = otpId;
      }
    }
  },
});

export const {setLoginFormError, setLoginFormState } = formStateSlice.actions;
export default formStateSlice.reducer;
